<template>
  <footer>
    <div class="footercontainer">
      <div class="columns">
        <div class="clm01">
          <div class="text">
            Türkiye Cumhuriyeti Ticaret Bakanlığı, 6563 sayılı Elektronik
            Ticaretin Düzenlenmesi Hakkında Kanun ve ilgili mevzuat kapsamında,
            Türkiye Odalar ve Borsalar Birliği'ni ticari elektronik ileti
            izinlerinin ve şikâyet süreçlerinin yönetilebildiği ulusal bir
            platform kurmakla görevlendirmiştir. İleti Yönetim Sistemi A.Ş.,
            Türkiye Odalar ve Borsalar Birliği tarafından bu amaçla hizmet
            vermek üzere kurulmuştur. Şirketin tek hissedarı Türkiye Odalar ve
            Borsalar Birliği’dir.
          </div>

          <a class="mt isocert" :href="`${origin}/iys/hakkimizda`">
            <img
              class="isoimg"
              src="@/assets/img/new_certs-no-bg.png"
              alt="İleti Yönetim Sistemi ISO"
            />
          </a>
          <!-- <div class="mt isocert"><img class="isoimg" src="@/assets/img/iso-cert.svg" alt="İleti Yönetim Sistemi ISO" /></div>
          <div class="mt isocert">
            <img class="isoimg" src="@/assets/img/cert-20000-1.svg" alt="İleti Yönetim Sistemi ISO"/>
            <img style="margin-left:6px;" class="isoimg" src="@/assets/img/cert-27701.svg" alt="İleti Yönetim Sistemi ISO" />
          </div> -->

          <div class="copy">{{currentYear}} Tüm Hakları Saklıdır ©</div>
        </div>

        <div class="clm02">
          <div class="fnavlinks">
            <router-link to="/iys/nedir"
              >İleti Yönetim Sistemi Nedir?</router-link
            >
            <router-link to="/hizmetlerimiz">Hizmetlerimiz</router-link>
            <router-link to="/vatandas-hizmetleri"
              >Vatandaş Hizmetleri</router-link
            >
            <router-link to="/hizmet-saglayici/temel-hizmetler"
              >Temel Hizmetler</router-link
            >
            <router-link to="/hizmet-saglayici/kurumsal-hizmetler"
              >Kurumsal Hizmetler</router-link
            >
            <router-link to="/hizmet-saglayici/basvuru/nasil-yapilir"
              >Başvuru</router-link
            >
            <router-link to="/iys/sss">Sıkça Sorulan Sorular</router-link>
            <!-- <a href="https://dev.iys.org.tr" target="_blank"
              >Geliştirici Merkezi</a
            > -->
            <router-link to="/iletisim">İletişim</router-link>
          </div>

          <!-- <div class="mt tablet isocert">
            <img
              class="isoimg"
              src="@/assets/img/iso-cert.svg"
              alt="İleti Yönetim Sistemi ISO"
            />
          </div>
          <div class="mt tablet isocert">
            <img
              class="isoimg"
              src="@/assets/img/cert-20000-1.svg"
              alt="İleti Yönetim Sistemi ISO"
            />
            <img
              style="margin-left: 6px"
              class="isoimg"
              src="@/assets/img/cert-27701.svg"
              alt="İleti Yönetim Sistemi ISO"
            />
          </div> -->

          <div class="mt tablet isocert">
            <img
              class="isoimg"
              src="@/assets/img/certs-no-bg.png"
              alt="İleti Yönetim Sistemi ISO"
            />
          </div>
        </div>

        <div class="clm03">
          <div class="logo">
            <img
              src="@/assets/img/iys-logo.svg"
              alt="İleti Yönetim Sistemi Vektörel Logo"
            />
          </div>

          <div class="adresler">
            <div class="adres">
              <b>Merkez</b>
              <br />TOBB İkiz Kuleleri C Blok Dumlupınar Bulv. No:252, 06530
              Çankaya / Ankara
            </div>
            <div class="adres">
              <b>İstanbul Ofis</b>
              <br />Esentepe Mah. Harman 1 Sokak No:10 TOBB Plaza 34394 Şişli /
              İstanbul
            </div>

            <!-- <div class="adres pcenter phone items-center">
              <img src="@/assets/img/ico_phone.svg" alt="phone" />
              <div>
                <b class="phone blue">0850 333 3338</b>
                <b class="phone blue">0212 939 3338</b>
              </div>
            </div> -->
          </div>

          <div class="yasallinks">
            <div class="yasal">
              <router-link to="/iys/cerez-ve-izleme-araci-politikasi"
                >Çerez ve İzleme Aracı Politikası</router-link
              >
            </div>
            <div class="yasal">
              <router-link to="/iys/api-lisans-kosullari"
                >API Lisans Koşulları</router-link
              >
            </div>
            <div class="yasal">
              <router-link to="/iys/kvkk-ve-gizlilik"
                >Kişisel Verilerin Korunması ve İşlenmesi Hakkında Genel
                Bilgilendirme</router-link
              >
            </div>
          </div>

          <div class="sm normal">
            <div class="logos">
              <!-- <a href="http://fb.me/iletiyonetim" target="_blank">
                <img
                  src="@/assets/img/icons/sm/sm-facebook.svg"
                  alt="facebook"
                />
                <img
                  src="@/assets/img/icons/sm/sm-facebook-o.svg"
                  alt="facebook"
                  class="sm-img-sec"
                />
              </a> -->
              <!-- <a href="https://twitter.com/iletiyonetim" target="_blank">
                <img src="@/assets/img/icons/sm/sm-twitter.svg" alt="twitter" />
                <img
                  src="@/assets/img/icons/sm/sm-twitter-o.svg"
                  alt="twitter"
                  class="sm-img-sec"
                />
              </a> -->
              <a href="https://twitter.com/iletiyonetim" target="_blank">
                <img src="@/assets/img/icons/sm/sm-x.svg" alt="twitter"/>
                <img
                  src="@/assets/img/icons/sm/sm-x-o.svg"
                  alt="twitter"
                  class="sm-img-sec"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/i%CC%87leti-y%C3%B6netim-sistemi"
                target="_blank"
              >
                <img
                  src="@/assets/img/icons/sm/sm-linkedin.svg"
                  alt="linkedin"
                />
                <img
                  src="@/assets/img/icons/sm/sm-linkedin-o.svg"
                  alt="linkedin"
                  class="sm-img-sec"
                />
              </a>
              <a
                href="https://www.youtube.com/channel/UCwhKVzK1oKZ2GAJ-rO4FP5A"
                target="_blank"
              >
                <img src="@/assets/img/icons/sm/sm-youtube.svg" alt="youtube" />
                <img
                  src="@/assets/img/icons/sm/sm-youtube-o.svg"
                  alt="youtube"
                  class="sm-img-sec"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
// var classNames = [];
// if (navigator.userAgent.match(/(iPad|iPhone|iPod)/i)) classNames.push('device-ios');
// if (navigator.userAgent.match(/android/i)) classNames.push('device-android');

// var html = document.getElementsByTagName('html')[0];

// if (classNames.length) classNames.push('on-device');
// if (html.classList) html.classList.add.apply(html.classList, classNames);
import { mapMutations } from "vuex";
export default {
  methods: {
    ...mapMutations("app", ["SET_MODAL"]),
  },
  computed: {
    currentYear() {
      return new Date().getFullYear();
    },

    origin ()  {
      return window.location.origin;
    }
  },
};
</script>

<style scoped>
/*
.device-android .test { color: red; }
.device-ios     .test { color: greenyellow; }
*/

.text {
  font-size: 0.9rem;
}

.yasallinks {
  margin: 40px 0;
}

.yasal {
  margin-bottom: 10px;
}

.yasal a {
  color: #55565e;
  font-size: 0.8rem;
  font-weight: 600;
  line-height: 1.2;
}
.yasal a:hover {
  color: #4da0df;
}

.phone {
  display: flex;
}

.phone img {
  width: 1rem;
  height: 1rem;
  margin-right: 0.2vw;
}

.adres {
  margin: 0 0 2vh 0;
}

/*  on phones ****************************************************************************************************************************************************************/

@media screen and (max-aspect-ratio: 13/9) and (max-width: 500px) {
  .adresler {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  .adres {
    width: 50%;
    margin: 0;
  }
  .pcenter {
    margin: 2vh auto 0 auto;
    width: auto;
  }
}
</style>
